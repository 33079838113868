import React from 'react'
import { Box, Typography, Card, CardContent, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  height: 'auto',
  px: 2,
  py: 3,
}))

const CustomerProfileCard = (): JSX.Element => {
  return (
    <>
      <StyledCard>
        <CardContent sx={{ width: '100%' }}>
          <Stack spacing={2}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant='h5'>Kazem Daashi</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography
                variant='subtitle2'
               
              >
                1057 points
              </Typography>
              <Typography variant='subtitle2'>Since 2022-04-12</Typography>
            </Box>
          </Stack>
        </CardContent>
      </StyledCard>
    </>
  )
}

export default CustomerProfileCard

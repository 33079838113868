import { useState } from "react";

const useLocalStorage = <T>(keyName:string, defaultValue:T):[T | null,(newValue:T|null)=>void] => {
    const [storedValue, setStoredValue] = useState<T | null>(() => {
        try {
            const value = window.localStorage.getItem(keyName);

            if (value !==null) {
                return JSON.parse(value);
            }

            window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
            return defaultValue;

        } catch (err) {
            return defaultValue;
        }
    });

    const setValue = (newValue:T | null):void => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err) {
            console.log(err)
         }
        setStoredValue(newValue);
    };

    return [storedValue, setValue];
};
export default useLocalStorage
import React from "react"
import { Navigate, useLoaderData } from "react-router-dom"
import { useCustomerAuth } from "hooks/useCustomerAuth"
import { type CustomerUser } from "types/auth"

const OneTimePassword = ():JSX.Element=>{
    const {customer , redirect} = useLoaderData() as {customer:CustomerUser , redirect:string | null}
    const {login} = useCustomerAuth()
    login(customer)
    localStorage.setItem('customerUser' , JSON.stringify(customer))
    if (redirect !== null){
        return <Navigate  to={redirect}/>
    }
    else{
        return <Navigate  to={'/userprofile'}/>

    }
 
}


export default OneTimePassword
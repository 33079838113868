import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Typography,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  FormControl,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
// component
import Rate from 'components/rate/Rate'
import type { Business } from 'types/customer'

import requestInstance from 'utils/request'
import moment from 'moment'
// import SurveyProfileForm from './SurveyProfileForm'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import InputAdornment from '@mui/material/InputAdornment'
import TodayIcon from '@mui/icons-material/Today'
import { AppCountrySelect } from 'sections/@dashboard/app'
import countries from '_mock/countries'
import { type salesRecord } from 'types/salesRecord'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
  // color: theme.palette.text,
}))

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  height: 'auto',
  px: 2,
  py: 3,
  // boxShadow: 0,
  textAlign: 'center',
  // color: (theme) => theme.palette[color].darker,
  // bgcolor: (theme) => theme.palette[color].lighter,
}))

interface SurveyAnonnymousStepperProps {
  business: Business
}

const SurveyAnonnymousStepper = ({ business }: SurveyAnonnymousStepperProps): JSX.Element => {
  // page state
  const [profileCollapse, setProfileCollapse] = useState<boolean>(false)
  const [descCollapse, setDescCollapse] = useState<boolean>(false)

  // profile states
  const [name, setName] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [dob, setDob] = useState<string | null>(null)
  const [gender, setGender] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [countryPhone, setCountryPhone] = useState<string>(
    countries.find((item) => business.country === item.code)?.phone ?? '',
  )
  const [saleId, setSaleId] = useState<number>()

  //  Rate states
  const [description, setDescription] = useState<string>('')
  const [rate, setRate] = useState<number>(-1)
  const [loadingButton, setLoadingButton] = useState(false)
  const [surveyDone, setSurveyDone] = useState<boolean>(false)

  // theme
  // const theme = useTheme()
  // const matches = useMediaQuery(theme.breakpoints.up('md'))

  const handleRateChange = (rate: number): void => {
    requestInstance
      .post<salesRecord>(`sale/`, {
        rate,
        business: business.id,
      })
      .then((res) => {
        if (res.status === 201) {
          setRate(rate)
          setSaleId(res.data.id)
          // seSaleId(res.data.id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmit = (): void => {
    setLoadingButton(true)
    Boolean(saleId) &&
      requestInstance
        .patch(`sale/${saleId}/`, {
          description,
        })
        .then((res) => {
          if (res.status === 200) {
            setDescCollapse(true)
            // enqueueSnackbar('your opinion submitted successfuly', { variant: 'success' })
            // setProfileCollapse(true)
            // setSurveyDone(true)
          }
          setLoadingButton(false)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoadingButton(false)
        })
  }
  const profileSubmitHandler = (): void => {
    requestInstance
      .patch(`sale/customer/${saleId}/`, {
        customer: {
          ...(name !== '' && { full_name: name }),
          ...(mobile !== '' && { mobile }),
          ...(dob !== null && { dob: moment(dob).format('YYYY-MM-DD') }),
          ...(gender !== '' && { gender }),
          ...(email !== '' && { email }),
          ...(countryPhone !== '' && { country_code: countryPhone }),
        },
        business: business.id,
      })
      .then((res) => {
        if (res.status === 200) {
          // enqueueSnackbar('your opinion submitted successfuly', { variant: 'success' })
          setProfileCollapse(false)
          setSurveyDone(true)
        }
        setLoadingButton(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  return (
    <>
      <StyledContent>
        <Avatar src={business.icon} alt='photoURL' sx={{ width: 96, height: 96 }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            {business.name}
          </Typography>
        </Box>

        <StyledCard>
          <CardContent>
            {surveyDone ? (
              <Typography sx={{ mt: 2, mb: 1 }}>Thank you for your time.</Typography>
            ) : (
              <Box>
                {/* <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}> */}
                <Collapse in={rate < 0} timeout='auto' unmountOnExit>
                  <Typography sx={{ color: 'text.secondary' }}>
                    From 0 to 10, how likely are you to recommend us to a friend or colleague?
                  </Typography>
                  {/* <Rate value={rate} setter={setRate} lastSaleId={lastSaleId} /> */}
                  <Rate value={rate} onChange={handleRateChange} />
                  {/* <Divider variant="middle" /> */}
                </Collapse>

                <Collapse in={rate >= 0 && !descCollapse} timeout='auto'>
                  {rate < 7 && (
                    <Box>
                      <Typography sx={{ color: 'text.secondary' }}>
                        What are the main reasons for your satisfaction or dissatisfaction?
                      </Typography>
                      <TextField
                        multiline
                        rows={6}
                        placeholder='Write here...'
                        sx={{ padding: '15px 0', width: '100%' }}
                        value={description}
                        onChange={(event) => {
                          setDescription(event.target.value)
                        }}
                      />
                      <CardActions sx={{ px: 0 }}>
                        <LoadingButton
                          loading={loadingButton}
                          sx={{ width: '100%' }}
                          size='large'
                          variant='contained'
                          onClick={handleSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </CardActions>
                    </Box>
                  )}
                  {rate >= 7 && (
                    <Box>
                      <Typography sx={{ mt: 2, mb: 1 }}>Thank you for your time.</Typography>
                      <Typography sx={{ color: 'text.secondary' }}>
                        Please leave us a review on Google and tell your most satisfaction reasons.
                      </Typography>
                      <CardActions>
                        <Button
                          sx={{ width: '100%' }}
                          size='large'
                          variant='contained'
                          onClick={() => window.open(business?.google_link, '_self')}
                        >
                          Open Google
                        </Button>
                      </CardActions>
                    </Box>
                  )}
                </Collapse>
                <Collapse in={profileCollapse} timeout='auto'>
                  <form
                    autoComplete='off'
                    // onSubmit={() => { onSubmit(10) }}
                  >
                    <Grid
                      sx={{ padding: '15px 0' }}
                      container
                      spacing={2}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Grid
                          flexDirection={'row'}
                          spacing={2}
                          display={'flex'}
                          container
                          flexWrap={'nowrap'}
                        >
                          <Grid item xs={4}>
                            <AppCountrySelect
                              countryPhone={countryPhone}
                              onChange={setCountryPhone}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              type='tel'
                              placeholder='Mobile'
                              inputProps={{
                                type: 'tel',
                              }}
                              value={mobile}
                              fullWidth
                              onChange={(event) => {
                                setMobile(event.target.value)
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl fullWidth sx={{ textAlign: 'left' }}>
                          <TextField
                            key='fullName'
                            name='fullName'
                            label='Name'
                            fullWidth
                            autoComplete='none'
                            value={name}
                            onChange={(event) => {
                              setName(event.target.value)
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl fullWidth sx={{ textAlign: 'left' }}>
                          <FormLabel
                            id='demo-row-radio-buttons-group-label'
                            sx={{ textAlign: 'left' }}
                          >
                            Gender (optional)
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={gender}
                            onChange={(event) => {
                              console.log(gender)
                              setGender(event.target.value)
                            }}
                          >
                            <FormControlLabel value='F' control={<Radio />} label='Female' />
                            <FormControlLabel value='M' control={<Radio />} label='Male' />
                            <FormControlLabel value='O' control={<Radio />} label='Other' />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl fullWidth sx={{ textAlign: 'left' }}>
                          <MobileDatePicker
                            // label="Date of birth (optional)"
                            sx={{ width: '100%' }}
                            value={dob}
                            onChange={(newValue) => {
                              setDob(newValue)
                            }}
                            slots={{
                              textField: (params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    placeholder: 'Date of birth (optional)',
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        <TodayIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl fullWidth sx={{ textAlign: 'left' }}>
                          <TextField
                            key='email'
                            name='email'
                            label='Email (optional)'
                            fullWidth
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value)
                            }}
                            autoComplete='none'
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </form>
                  <CardActions sx={{ px: 0 }}>
                    <LoadingButton
                      loading={loadingButton}
                      sx={{ width: '100%' }}
                      size='large'
                      variant='contained'
                      onClick={profileSubmitHandler}
                    >
                      Submit profile
                    </LoadingButton>
                  </CardActions>
                </Collapse>

                {!profileCollapse && rate >= 0 && (
                  <Button
                    sx={{ width: '100%' }}
                    size='large'
                    onClick={() => {
                      setProfileCollapse(true)
                    }}
                  >
                    complete your profile
                  </Button>
                )}
                {/* </CardContent> */}
              </Box>
            )}
          </CardContent>
        </StyledCard>
        <Box sx={{ textAlign: 'center', marginTop: 'auto', marginBottom: '50px' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default SurveyAnonnymousStepper

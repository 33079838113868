import React from 'react'
import { useLoaderData } from 'react-router-dom'
import SurveyAnonymousStepper from 'sections/survey/SurveyAnonymousStepper'
// import SurveyCustomerForm from 'sections/survey/SurveyCustomerStepper'
import { type Customer, type Business } from 'types/customer'
import SurveyBusinessStepper from 'sections/survey/SurveyBusinessStepper'
import SurveyCustomerStepper from 'sections/survey/SurveyCustomerStepper'
const renderedComponent = (type: string, business: Business, customer: Customer): JSX.Element => {
  if (type === 'anonymous') return <SurveyAnonymousStepper business={business} />
  if (type === 'business') return <SurveyBusinessStepper business={business} customer={customer} />
  if (type === 'customer') return <SurveyCustomerStepper business={business} />
  return <div>nothing correct happend</div>
}

const SurveyPage = (): JSX.Element => {
  const state = useLoaderData() as { type: string; business: Business; customer: Customer }

  return <>{renderedComponent(state.type, state.business, state.customer)}</>
}
export default SurveyPage

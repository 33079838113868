import React from 'react'
// @mui
import { alpha, styled } from '@mui/material/styles'
import { Card, Typography, Grid } from '@mui/material'
import { type SxProps } from '@mui/material/styles'
// utils
import { fShortenNumber } from '../../../utils/formatNumber'
// components
import Iconify from '../../../components/iconify'
// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}))

// ----------------------------------------------------------------------


interface AppWidgetSummaryMobileProps {
  title: string[]
  color: 'primary' | 'secondary' | 'warning' | 'info' | 'success'
  icon: string[]
  total: number[]
  sx?: SxProps
}

const AppWidgetSummaryMobile =({
  title,
  total,
  icon,
  color = 'primary',
  sx,
  ...other
}: AppWidgetSummaryMobileProps) : JSX.Element=> {
  return (
    <Card
      sx={{
        py: 2,
        // boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: 'white',
        ...sx,
        // direction: 'row',
        // display: 'flex',
        // justifyContent: 'center'
      }}
      {...other}
    >
      <Grid direction={'row'} justifyContent={'center'} container spacing={2}>
        {title.map((item, index) => {
          return (
            <Grid item xs={3} justifyContent={'center'} key ={`AppWidgetSummaryMobile-${index}`}>
              <StyledIcon
                sx={{
                  color: (theme) => theme.palette[color].dark,
                  backgroundImage: (theme) =>
                    `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                      theme.palette[color].dark,
                      0.24,
                    )} 100%)`,
                }}
              >
                <Iconify icon={icon[index]} width={24} />
              </StyledIcon>

              <Typography variant='h5'>{fShortenNumber(total[index])}</Typography>

              <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
                {item}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </Card>
  )
}
export default AppWidgetSummaryMobile
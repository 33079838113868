import React from 'react'
import { useSnackbar } from 'notistack'
import { notificationActions } from 'store/notification-slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import type { notif } from 'store/notification-slice'
let displayed: string[] = []

const Notifier = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector((store) => store.Notification.notifications)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id: notif['key']): void => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: notif['key']): void => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  React.useEffect(() => {
    notifications.forEach(({ key, message, dismissed, options }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(notificationActions.removeSnackbar(`${myKey}`))
          removeDisplayed(`${myKey}`)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return <></>
}

export default Notifier

import { Helmet } from 'react-helmet-async';

import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable , MRT_ColumnDef } from 'material-react-table';
import axios from 'axios';
import { Card } from '@mui/material';
import moment from 'moment';

// @mui

// ----------------------------------------------------------------------

type Comment ={
    name:string,
    phone:string,
    deliveryDate:string,
    rate:string,
    feedbackDate:string,
    description:string,
    
}

const AppFeedbacksTable = ({id}:{id:string}) => {
  //  data and fetching state
  const [data, setData] = useState<Comment[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //  table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //  if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await axios.get(
          `sale/?business=${id}&per_page=${pagination.pageSize}&page=${pagination.pageIndex + 1}`
        );
        const json = await response.data;
        console.log(json);
        const list = json.data.map((item:any) => ({
          name: item.customer ? item.customer.full_name : '-',
          phone: item.customer ? item.customer.country_code + item.customer.mobile : '-',
          deliveryDate: item.delivery_date ? moment(item.delivery_date).format('YYYY-MM-DD HH:mm') : '-',
          rate: item.rate,
          feedbackDate: item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD HH:mm') : '-',
          description: item.description,
        }));
        console.log('list');
        console.log(list);
        setData(list);
        setRowCount(json.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

  const columns = useMemo<MRT_ColumnDef<Comment>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 2,
      },

      {
        accessorKey: 'phone',
        header: 'Phone',
        size: 2,
      },
      {
        accessorKey: 'deliveryDate',
        header: 'Delivery Date',
        size: 2,
      },
      {
        accessorKey: 'rate',
        header: 'rate',
        size: 1,
      },
      {
        accessorKey: 'feedbackDate',
        header: 'Feedback Date',
        size: 2,
      },
      {
        accessorKey: 'description',
        header: 'Comment',
      },
    ],
    []
  );

  return (
    <Card sx={{ backgroundColor: 'white' }}>
      <MaterialReactTable
        enableFilters={false}
        columns={columns}
        data={data}
        enableSorting={false}
        // getRowId={(row) => row.phoneNumber}
        initialState={{ showColumnFilters: true }}
        manualPagination
        muiTablePaperProps={{}}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
    </Card>
  );
};

export default AppFeedbacksTable;

import React from 'react'
import { Avatar, Box, Typography, Stack, Divider } from '@mui/material'

import { styled } from '@mui/material/styles'
// component
import { CustomerProfileCard, CustomerProfileRewardCard } from 'sections/customerProfile'
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
}))

const CustomerProfile = (): JSX.Element => {
  return (
    <>
      <StyledContent>
        <Avatar
          src='/assets/images/avatars/success.png'
          alt='photoURL'
          sx={{ width: 96, height: 96 }}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            Collins Corp.
          </Typography>
        </Box>
        <Stack  spacing={2}>
          <CustomerProfileCard />
          <Divider flexItem>REWARDS</Divider>
          <CustomerProfileRewardCard />
          <CustomerProfileRewardCard />
          <CustomerProfileRewardCard />
          {/* <CustomerProfileRewardCard /> */}
        </Stack>
        <Box sx={{ textAlign: 'center', marginTop:'auto' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default CustomerProfile

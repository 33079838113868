import React from 'react'
import { Avatar, Box, Typography, Stack, Divider } from '@mui/material'
import ResReward1 from 'sections/customerProfile/ResReward1'
import ResReward2 from 'sections/customerProfile/ResReward2'
import ResReward3 from 'sections/customerProfile/ResReward3'
import ResProfileCard from 'sections/customerProfile/ResProfileCard'
import { styled } from '@mui/material/styles'
// component
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
}))

const ResProfile = (): JSX.Element => {
  return (
    <>
      <StyledContent>
        <Avatar src='/assets/images/demo/TFS logo.png' alt='photoURL' sx={{ width: 96, height: 96 }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            Demo Restaurant
          </Typography>
        </Box>
        <Stack spacing={2} sx={{width:'100%'}}>
          <ResProfileCard />
          <Divider flexItem>REWARDS</Divider>
          <ResReward2 />
          <ResReward3 />
          <ResReward1 />
          {/* <CustomerProfileRewardCard /> */}
        </Stack>
        <Box sx={{ textAlign: 'center', marginTop: 'auto' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default ResProfile

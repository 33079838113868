import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type OptionsObject } from 'notistack'

export interface notif {
  message: string
  key: string
  dismissed: boolean
  options?:{variant: OptionsObject['variant']}
}
interface NotificationState {
  notifications: notif[]
  //   message: string
}

const initialState: NotificationState = {
  notifications: [],
}

// const increment = createAction<number, 'increment'>('increment')

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    removeSnackbar(state, action:PayloadAction<notif['key']>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload,
      )
    },
    enqueueSnackbar(state, action: PayloadAction<notif>) {
    //   state.notifications.push(action.payload)
    state.notifications=[action.payload]
    },
    increment(state, action: PayloadAction<number>) {
      // action is any here
      //   action.payload
    },
    logout(state) {},
  },
})

export const notificationActions = notificationSlice.actions
export default notificationSlice

import React from 'react'
import { createBrowserRouter, defer } from 'react-router-dom'
import requestInstance from './utils/request'
import OneTimePassword from 'components/oneTimePassword/OneTimePassword'
// layouts
import SimpleLayout from './layouts/simple'
import ProtectedLayout from './layouts/protected/ProtectedLayout'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import CustomerAuthLayout from 'layouts/CustomerAuthLayout/CustomerAuthLayout'
//  pages
import LoginPage from './pages/LoginPage'
import DashboardAppPage from './pages/DashboardAppPage'
import CustomerPage from 'pages/CustomerPage'
import SurveyPage from 'pages/SurveyPage'
import CustomerProfile from 'pages/CustomerProfile'
import RewardDetailPage from 'pages/RewardDetailPage'
import ResProfile from 'pages/ResProfile'
import ResDetailPage from 'pages/ResDetailPage'
import BeautyProfile from 'pages/BeautyProfile'
import BeautyDetailPage from 'pages/BeautyDetailPage'
//  types
import type { CustomerUser, user } from './types/auth'
import { type Customer, type CustomerListAPI, type Business } from 'types/customer'

const getUserData = async (): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const user = window.localStorage.getItem('user')
    // reject(new Error('aesdgsgs'))
    user !== null && resolve(user)
    reject(new Error('user not found'))
  })
}
const getCustomerData = async (): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const user = window.localStorage.getItem('customerUser')
    // reject(new Error('aesdgsgs'))
    user !== null && resolve(user)
    reject(new Error('user not found'))
  })
}
const routes = [
  {
    path: '/',

    element: <SimpleLayout />,
    children: [
      {
        path: '/survey',
        element: <SurveyPage />,
        loader: async ({ request }: { request: Request }) => {
          const url = new URL(request.url)
          const customerId = url.searchParams.get('customer')
          const type = url.searchParams.get('type')
          const businessId = url.searchParams.get('business')

          const businessRes = await requestInstance.get<Business>(
            encodeURI(`business/${businessId}`),
          )
          if (
            businessId !== null &&
            (type === null || type === 'anonymous') &&
            customerId === null
          ) {
            return { type: 'anonymous', business: businessRes.data }
          }
          if (businessId !== null && type === 'business' && customerId !== null) {
            const customerRes = await requestInstance.get<Customer>(
              encodeURI(`customer/${customerId}`),
            )
            return { type: 'business', business: businessRes.data, customer: customerRes.data }
          }
          if (businessId !== null && type === 'customer') {
            return { type: 'customer', business: businessRes.data }
          }
          throw new Error('sdegeasg')
        },
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        element:<OneTimePassword />,
        path: '/userverification',
        loader: async ({ request }: { request: Request }) => {
          const url = new URL(request.url)
          const verificationCode = url.searchParams.get('code')
          const redirect = url.searchParams.get('redirect')
          try{
            const customer = await requestInstance.put<CustomerUser>(`/customer/verify/`, {
              verification_code: verificationCode,
            })
            return {customer , redirect}

          }catch (err){
            
          }
        },
      },
      {
        path: '/profile',
        element: <CustomerProfile />,
       
      },
      {
        path: '/demores',
        element: <ResProfile />,
       
      },
      {
        path: '/demoresdetail',
        element: <ResDetailPage />,
       
      },
      {
        path: '/rewarddetail',
        element: <RewardDetailPage />,
       
      },
      {
        path: '/demobeauty',
        element: <BeautyProfile />,
       
      },
      {
        path: '/demobeautydetail',
        element: <BeautyDetailPage />,
       
      },
    ],
  },

  {
    element: <AuthLayout />,
    loader: () => defer({ userPromise: getUserData() }),
    children: [
      {
        element: <ProtectedLayout />,
        children: [
          {
            element: <DashboardAppPage />,
            path: '/dashboard',
            loader: async () => {
              const user: user | null =
                localStorage.getItem('user') !== null
                  ? JSON.parse(localStorage.getItem('user') ?? `{"id":"null"}`)
                  : null
              if (user !== null) {
                const res = await requestInstance.get(`business/${user?.id?.toLowerCase()}`)
                return res.data
              }
              return null
            },
          },
          {
            element: <CustomerPage />,
            path: '/customer',
            loader: async ({ request }: { request: Request }) => {
              const url = new URL(request.url)
              const mobile = url.searchParams.get('mobile')
              const countryCode = url.searchParams.get('country_code')
              const businessId = url.searchParams.get('business')
              const res = await requestInstance.get<CustomerListAPI>(
                encodeURI(`customer?${url.searchParams.toString()}`),
              )
              if (res.data.data.length > 0) {
                return res.data.data[0]
              }
              const customer: Customer = {
                gender: '',
                full_name: '',
                email: '',
                mobile: mobile ?? undefined,
                country_code: countryCode ?? '',
                business: { id: businessId ?? undefined },
              }
              return customer
            },
          },
        ],
      },
    ],
  },
  {
    element: <CustomerAuthLayout />,
    loader: () => defer({ userPromise: getCustomerData() }),
  },
]
const router = createBrowserRouter(routes)
export default router

import React from 'react'

import {
  Avatar,
  Box,
  Typography,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  //   textAlign: 'center',
  alignItems: 'center',
}))

const StyledCard = styled(Card)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  width: '100%',
  //   height:'100%',
  //   justifyContent: 'space-between',
  flex: 1,
}))

const RewardDetailPage = (): JSX.Element => {
  return (
    <>
      <StyledContent>
        <Avatar
          src='/assets/images/avatars/success.png'
          alt='photoURL'
          sx={{ width: 96, height: 96 }}
        />
        <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
          Collins Corp.
        </Typography>
        <StyledCard>
          <CardMedia
            component='img'
            src='https://placehold.co/400x400'
            // image='/assets/images/covers/cover_9.jpg'
            alt='Live from space album cover'
          />
          <CardContent sx={{ overflowY: 'auto' }}>
            <Typography variant='h5'>Lorem ipsum dolor</Typography>
            <Typography variant='body1'>
              Sed ut sodales est. Mauris leo nunc, fringilla eget velit ut, egestas imperdiet justo.
              Aenean ac ligula sem. Suspendisse potenti. Suspendisse id tellus id felis venenatis
              lacinia. Nulla eget viverra dui. Aliquam dignissim, odio ac placerat aliquam, nibh
              diam facilisis mauris, at porta purus velit nec orci. Suspendisse nec nulla mauris.
              Cras at bibendum enim, vel venenatis risus. Nam fringilla euismod purus at facilisis.
            </Typography>
            <Divider sx={{my:'15px'}} />
            <Typography variant='h5'>Lorem ipsum</Typography>
            <Typography variant='body1'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer felis eros, tempus
              sed varius ut, dignissim sed quam. Nam eget urna elementum, fermentum dolor in,
              dapibus ipsum. Mauris at ullamcorper lectus. Duis ultricies arcu ac lacus maximus, non
              tincidunt nisi ullamcorper. Phasellus erat ligula, auctor et ipsum at, placerat porta
              eros. Duis eget eleifend nisi. Proin pellentesque arcu eu nibh vulputate tincidunt id
              non purus. Donec velit turpis, posuere ac varius vel, aliquam eu nibh. Maecenas in leo
              convallis, lobortis sem et, pulvinar erat. Morbi auctor tristique lacus, sit amet
              rhoncus elit dignissim ullamcorper.
            </Typography>
            <Divider sx={{my:'15px'}} />
            <Typography variant='h5'>
              Lorem ipsum pulvinar erat. Morbi auctor tristique lacus,
            </Typography>
            <Typography variant='body1'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer felis eros, tempus
              sed varius ut, dignissim sed quam. Nam eget urna elementum, fermentum dolor in,
            </Typography>
          </CardContent>
        </StyledCard>
        <Box sx={{ marginTop: 'auto' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
      <Button
        size='large'
        fullWidth
        variant='contained'
        sx={{ position: 'sticky', bottom: 0, borderRadius: 0 }}
      >
        redeem
      </Button>
    </>
  )
}
export default RewardDetailPage

import React, { useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import axios from 'axios'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
import makeAnimated from 'react-select/animated'
import { type Customer, type Option, type Product } from 'types/customer'
import requestInstance from 'utils/request'
import {
  Card,
  Button,
  Stack,
  FormControl,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Container,
  type SxProps,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TodayIcon from '@mui/icons-material/Today'
import { useNavigate, useLoaderData } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import AsyncSelect from 'react-select/async'
import { type MultiValue } from 'react-select'

const loadProducts = async (id: string): Promise<Option[]> => {
  const url = `product?industry__business=${id}&per_page=300`
  return await axios.get(`${url}`).then((response) => {
    return response.data.data.map((item: Product) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  })
}
interface CustomerPageProps {
  sx?: SxProps
}
const CustomerPage = ({ sx }: CustomerPageProps): JSX.Element => {
  const animatedComponents = makeAnimated()
  const loaderData = useLoaderData() as Customer
  const [customer, setCustomer] = useState<Customer>(loaderData)
  const navigate = useNavigate()
  const [datetime, setDatetime] = useState<string>()
  const [loadingButton, setLoadingButton] = useState(false)
  const [productList, setProductList] = useState<MultiValue<Option>>([])
  const [amount, setAmount] = useState<number | ''>('')

  // const dispatch = useAppDispatch()
  const handleSave = (): void => {
    setLoadingButton(true)
   void requestInstance
      .post(`/sale/customer/`, {
        products:productList.map(product => product.value),
        delivery_date: datetime,
        amount: amount !== '' ? amount : null,
        customer : {...customer , business:customer.business?.id},
      }).then(res =>{
        if(res.status ===201){
          navigate(`/dashboard`)
        }
      }).finally(() => {
        setLoadingButton(false)
      })
  }

  return (
    <>
      {
        <>
          <Helmet>
            <title> Sale | EngagePackage </title>
          </Helmet>

          <Container maxWidth='xl'>
            <Grid container spacing={3} justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <Card
                    elevation={5}
                    sx={{
                      px: 4,
                      py: 5,
                      // boxShadow: 0,
                      textAlign: 'center',
                      // color: (theme) => theme.palette[color].darker,
                      // bgcolor: (theme) => theme.palette[color].lighter,
                      ...sx,
                    }}
                  >
                    <form autoComplete='off'>
                      <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              key='mobile'
                              name='mobile'
                              label='Mobile'
                              fullWidth
                              autoComplete='none'
                              value={customer.mobile}
                              disabled
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              // required
                              key='fullName'
                              name='fullName'
                              label='Name'
                              fullWidth
                              autoComplete='none'
                              value={customer.full_name}
                              onChange={(newValue) => {
                                setCustomer((prev) => {
                                  const newCustomer = { ...prev }
                                  newCustomer.full_name = newValue.target.value
                                  return newCustomer
                                })
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <FormLabel
                              id='demo-row-radio-buttons-group-label'
                              sx={{ textAlign: 'left' }}
                            >
                              Gender (optional)
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby='demo-row-radio-buttons-group-label'
                              name='row-radio-buttons-group'
                              value={customer.gender}
                              onChange={(event) => {
                                setCustomer((prev) => {
                                  const newCustomer = { ...prev }
                                  newCustomer.gender = event.target.value
                                  return newCustomer
                                })
                              }}
                            >
                              <FormControlLabel value='F' control={<Radio />} label='Female' />
                              <FormControlLabel value='M' control={<Radio />} label='Male' />
                              <FormControlLabel value='O' control={<Radio />} label='Other' />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <MobileDatePicker
                            format='YYYY-MM-DD'
                            label='Date of birth (optional)'
                            sx={{ width: '100%' }}
                            value={moment(customer.dob)}
                            onChange={(newValue) => {
                              setCustomer((prev) => {
                                console.log(newValue)
                                console.log(customer)
                                const newCustomer = { ...prev }
                                newCustomer.dob = moment(newValue).format('YYYY-MM-DD')
                                return newCustomer
                              })
                            }}
                            slots={{
                              textField: (params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        <TodayIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              key='email'
                              name='email'
                              label='Email (optional)'
                              fullWidth
                              autoComplete='none'
                              value={customer.email}
                              onChange={(newValue) => {
                                setCustomer((prev) => {
                                  const newCustomer = { ...prev }
                                  newCustomer.email = newValue.target.value
                                  return newCustomer
                                })
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <AsyncSelect
                              components={animatedComponents}
                              menuPortalTarget={document.body}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: '#00d664',
                                  neutral20: '#DFE3E8', // regular color
                                  neutral30: '#212B36', // hovered color
                                },
                              })}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: '6px',
                                  padding: '9px 5px',
                                  textAlign: 'left',
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              isMulti
                              defaultOptions
                              value={productList}
                              cacheOptions
                              loadOptions={async () => {
                                return await loadProducts(customer.business?.id ?? '')
                              }}
                              placeholder='Products (optional)'
                              onChange={(newval) => {
                                console.log(newval)
                                setProductList(newval)
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              id='outlined-amount'
                              type='number'
                              label='Amount in AED (optional)'
                              aria-describedby='filled-weight-helper-text'
                              inputProps={{
                                'aria-label': 'amount',
                              }}
                              sx={{ width: '100%' }}
                              value={amount}
                              onChange={(event) => {
                                setAmount(Number(event.target.value))
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <DateTimePicker
                              label='Delivery date & time (optional)'
                              value={moment(datetime)}
                              onChange={(newValue) => {
                                console.log(datetime)
                                setDatetime(newValue?.toISOString())
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          display={'flex'}
                          justifyContent={'space-between'}
                          flex={2}
                        >
                          <Button
                            color='secondary'
                            size='large'
                            variant='outlined'
                            sx={{ flex: 1, margin: '8px', marginLeft: 0 }}
                            onClick={() => {
                              navigate('/dashboard')
                            }}
                          >
                            Back
                          </Button>
                          <LoadingButton
                            loading={loadingButton}
                            sx={{ flex: 1, margin: '8px', marginRight: 0 }}
                            variant='contained'
                            size='large'
                            onClick={handleSave}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </form>
                  </Card>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </>
      }
    </>
  )
}
export default CustomerPage

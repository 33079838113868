import React from 'react'
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Stack,
  CardMedia,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  height: '120px',
  px: 2,
  py: 3,
}))

const BeautyReward2 = (): JSX.Element => {
  return (
    <>
      <StyledCard>
        <Box 
        sx={{ 
          // flex: {xs:20 , sm:10}, 
          display: 'flex' }}
        >
          <Box 
          // sx={{ flex: {xs:7 , sm:2}, display: 'flex' }}
          >
            <CardMedia
              component='img'
              sx={{ objectFit: 'contain' , height:'100%' }}
              // src='https://dummyimage.com/squarebutton'
              image='/assets/images/demo/Beauty-salon-reward-2.jpg'
              alt='Live from space album cover'
            />
          </Box>
          <Box
            component='div'
            sx={{
              // flex: {xs:13 , sm:8},
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <CardContent
              sx={{
                textAlign: 'left',
                marginBottom: 'auto',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
              }}
            >
              <Typography variant='h6'>Standard manicur</Typography>
              <Typography variant='subtitle2'>5 Stamps</Typography>

              <Typography
                variant='body2'
                color='text.secondary'
                noWrap
                sx={{ textOverflow: 'ellipsis' }}
              >
                Lorem Ipsum is simply
              </Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <Stack spacing={2} direction={'row'} alignItems={'center'}>
                {/* <Typography variant='subtitle2'>250 points</Typography> */}
                {/* <Button variant='contained'>detail</Button> */}
              </Stack>
            </CardActions>
          </Box>
        </Box>
      </StyledCard>
    </>
  )
}
export default BeautyReward2

import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classes from './Rate.module.css';

interface RateProps {
  value:number
  onChange:(number:number)=>void
}
const Rate = ({value , onChange}:RateProps):JSX.Element => {
  const [selected, setSelected] = useState<number>(value);
  const rates = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const styles = matches ? { padding: '0.5em 1em', width: '50px' } : { padding: '0.4em 0em', width: '28px' };
  return (
    <div className={classes.rating_scale}>
      {/* <div> */}
      {rates.map((item) => {
        return (
          <label key={item} htmlFor={`${item}`}>
            <input
              checked={Number(value) === Number(item)}
              type="radio"
              value={item}
              name="rad"
              id={`${item}`}
              onChange={(event) => {
                // setSelected(Number(event.target.value));
                // props.setter(event.target.value);
                console.log('4fgsdfg');
                onChange(Number(event.target.value));
                // axios
                //   .patch(`sale/${props.lastSaleId}/`, {
                //     rate:event.target.value,
                //   })
                //   .then((res) => {
                //     if (res.status === 200) {
                //       console.log('successful');
                //     }
                //   }).catch(err => console.log(err));
              }}
            />
            <span
              style={
                Number(selected) === Number(item)
                  ? {
                      ...styles,
                      color: 'white',
                      backgroundColor: 'grey',
                      // padding:'0.5em 1em'
                    }
                  : {
                      ...styles,
                      // padding:'0.5em 1em'
                    }
              }
            >
              {item}
            </span>
          </label>
        );
      })}
    </div>
  );
};
export default Rate;

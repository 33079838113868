import React from 'react'
// @mui
import { Card, Typography } from '@mui/material'
import { type SxProps, alpha, styled } from '@mui/material/styles'
// utils
import { fShortenNumber } from 'utils/formatNumber'
// compnents
import Iconify from 'components/iconify'
// ----------------------------------------------------------------------
const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}))

// ----------------------------------------------------------------------

interface AppWidgetSummaryProps {
  title: string
  color: 'primary' | 'secondary' | 'warning' | 'info' | 'success'
  icon: string
  total: number
  sx?: SxProps
}

const AppWidgetSummary = ({
  title,
  total,
  icon,
  color = 'primary',
  sx,
}: AppWidgetSummaryProps): JSX.Element => {
  console.log()
  return (
    <Card
      sx={{
        py: 2,
        // boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: 'white',
        ...sx,
      }}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24,
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} />
      </StyledIcon>

      <Typography variant='h3'>{fShortenNumber(total)}</Typography>

      <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  )
}
export default AppWidgetSummary

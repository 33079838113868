import { Helmet } from 'react-helmet-async'
// @mui
import { useTheme } from '@mui/material/styles'
import { Grid, Container, Typography, Fab } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import React, { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useLoaderData } from 'react-router-dom'
// components

// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppFeedbacksTable,
  AppWidgetSummaryMobile,
  AppSalesRecordModal,
} from 'sections/@dashboard/app'

import { type BusinessAPI } from 'types/dashboard'
import countries from '_mock/countries'
// ----------------------------------------------------------------------
const fabStyle: React.CSSProperties = {
  margin: 0,
  top: 'auto',
  right: 40,
  bottom: 40,
  left: 'auto',
  position: 'fixed',
}

const DashboardAppPage: React.FunctionComponent = () => {
  const state = useLoaderData() as BusinessAPI
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const handleOpen = (): void => {
    setOpen(true)
  }
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  console.log('state')
  console.log(state)
  console.log(countries.find((item) => item.code === state.country))
  return (
    <>
      <Helmet>
        <title> Dashboard | EngagePackage </title>
      </Helmet>
      <Fab variant='extended' style={fabStyle} color='primary' onClick={handleOpen}>
        <AddIcon sx={{ mr: 1 }} />
        Sales Record
      </Fab>
      <AppSalesRecordModal
        defaultCountryPhone={countries.find((item) => item.code === state.country)?.phone ?? ''}
        id={state.id}
        handleOpen={() => {
          setOpen(true)
        }}
        handleClose={() => {
          setOpen(false)
        }}
        open={open}
      />
      <Container maxWidth='xl'>
        <Typography variant='h4' sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  title='Customers Statistics'
                  subheader=''
                  chartLabels={state.dashboard.customer.recurring.map((item) => item.label)}
                  fill={['solid', 'gradient']}
                  chartData={[
                    {
                      name: 'Recurring Customers',
                      data: state.dashboard.customer.recurring.map((item) => item.value),
                      type: 'line',
                    },
                    {
                      name: 'New Customer',
                      type: 'area',
                      data: state.dashboard.customer.new.map((item) => item.value),
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Grid container spacing={3}>
                  {!matches && (
                    <>
                      <Grid item xs={12} lg={12} md={12}>
                        <AppWidgetSummaryMobile
                          title={['Total Customers', 'New Customers', 'Recurring Customers']}
                          total={[
                            state.dashboard.customer.total_count,
                            state.dashboard.customer.new_count,
                            state.dashboard.customer.recurring_count,
                          ]}
                          color='secondary'
                          icon={[
                            'ant-design:user-outlined',
                            'ant-design:user-switch-outlined',
                            'ant-design:user-switch-outlined',
                          ]}
                        />
                      </Grid>
                    </>
                  )}
                  {matches && (
                    <>
                      <Grid item xs={12} lg={12} md={12}>
                        <AppWidgetSummary
                          title='Total Customers'
                          total={state.dashboard.customer.total_count}
                          color='secondary'
                          icon={'ant-design:user-outlined'}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6} md={6}>
                        <AppWidgetSummary
                          title='New Customers'
                          total={state.dashboard.customer.new_count}
                          color='info'
                          icon={'ant-design:user-add-outlined'}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6} md={6}>
                        <AppWidgetSummary
                          title='Recurring Customers'
                          total={state.dashboard.customer.recurring_count}
                          color='warning'
                          icon={'ant-design:user-switch-outlined'}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              fill={['solid', 'gradient']}
              title='Daily NPS'
              subheader=''
              chartLabels={state.dashboard.nps.count_by_day.map((item) => item.label)}
              chartData={[
                {
                  name: 'Feedbacks',
                  type: 'column',
                  data: state.dashboard.nps.count_by_day.map((item) => item.value),
                },
                {
                  name: 'Average Score',
                  type: 'area',
                  data: state.dashboard.nps.by_day.map((item) => item.value),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              subheader={'dfsd'}
              title='Net Promoter Score'
              chartLabels={['Detractors', 'Passives', 'Promoters']}
              chartSeries={[
                state.dashboard.nps.by_score.detractors,
                state.dashboard.nps.by_score.passives,
                state.dashboard.nps.by_score.promoters,
              ]}
              chartColors={[
                theme.palette.error.main,
                theme.palette.warning.main,
                theme.palette.success.main,
              ]}
              nps={state.dashboard.nps.total_percent}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppFeedbacksTable id={state.id} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default DashboardAppPage

import React , { Suspense } from "react";
import { useLoaderData, useOutlet, Await, 
    // Navigate
} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomerAuthProvider } from "hooks/useCustomerAuth";

function ReviewsError():JSX.Element {
  // const error = useAsyncError();
  // return <div>ergtewrteg</div>;
  return <div>ergtewrteg</div>;
}
const CustomerAuthLayout=():JSX.Element =>{
  const outlet = useOutlet();

  const {userPromise} = useLoaderData() as {userPromise:string | null};
  console.log('authlayout')
  console.log(userPromise) 
  
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        errorElement={<ReviewsError />}
      >{(user) => (
        <CustomerAuthProvider userData={user}>
          {outlet}
        </CustomerAuthProvider>
      )}</Await>
    </Suspense>
  );
};
export default CustomerAuthLayout
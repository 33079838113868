import React from 'react'
import { Avatar, Box, Typography, Stack, Divider } from '@mui/material'
import BeautyProfileCard from 'sections/customerProfile/BeautyProfileCard'
import BeautyReward1 from 'sections/customerProfile/BeautyReward1'
import BeautyReward2 from 'sections/customerProfile/BeautyReward2'
import BeautyReward3 from 'sections/customerProfile/BeautyReward3'
import { styled } from '@mui/material/styles'
// component
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
}))

const BeautyProfile = (): JSX.Element => {
  return (
    <>
      <StyledContent>
        <Avatar
          src='/assets/images/demo/DIANA.jpg'
          alt='photoURL'
          sx={{ width: 96, height: 96 }}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            Demo Beauty
          </Typography>
        </Box>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <BeautyProfileCard />
          <Divider flexItem>REWARDS</Divider>
          <BeautyReward1 />
          <BeautyReward2 />
          <BeautyReward3 />
          {/* <CustomerProfileRewardCard /> */}
        </Stack>
        <Box sx={{ textAlign: 'center', marginTop: 'auto' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default BeautyProfile

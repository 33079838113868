import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import axios from 'axios'
import { useAuth } from '../../../hooks/useAuth'
import { type user } from 'types/auth'

// ----------------------------------------------------------------------

const LoginForm = ():JSX.Element => {
  const navigate = useNavigate()
  // const [showPassword, setShowPassword] = useState(false);
  const [bussinessID, setBussinessID] = useState<string>('')

  const { login } = useAuth()

  const loginHandler = ():void => {
    console.log('login hamdler')
    axios
      .get(`business/${bussinessID.toLowerCase()}`)
      .then((res) => {
        const user:user={username:"" , manager_full_name:res.data.manager_full_name , avatar:res.data.icon , email:res.data.email , id:res.data.id , token:''}
        localStorage.setItem('user' ,JSON.stringify(user))
        login({ ...res.data })
        navigate('/dashboard')
      })
      .catch((err) => {console.log(err)})
  }
  return (
    <>
      <Stack>
        <TextField
          sx={{ mb: 2 }}
          name='email'
          label='Business ID'
          onChange={(e) => {setBussinessID(e.target.value)}}
        />
      </Stack>
      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        onClick={loginHandler}
      >
        Login
      </LoadingButton>
    </>
  )
}
export default LoginForm

import { configureStore } from '@reduxjs/toolkit';

// import authSlice from './auth-slice';
// import initSlice from './initial-slice';
// import ExitListSlice from './exitList-slice';
// import EnterListSlice from './enterList_slice'
// import ModalSlice from './modal-slice'
import notificationSlice from './notification-slice';
// const authMiddleware = (store:any) => (next:any) => (action:any) => {
//   const result = next(action);
//   if ( action.type?.startsWith('enterlistslice/') ) {
//     const enterState = store.getState().EnterList;
//     localStorage.setItem('enterState', JSON.stringify(enterState))
//   }
//   return result;
// };

const store = configureStore({
  reducer: {
    Notification:notificationSlice.reducer
    // Auth: authSlice.reducer,
    // Init: initSlice.reducer,
    // ExitList: ExitListSlice.reducer,
    // EnterList: EnterListSlice.reducer,
    // Modal: ModalSlice.reducer
  },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware)
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export default store;

// import React, { useState } from 'react'
// import {
//   Avatar,
//   Box,
//   Typography,
//   Card,
//   CardContent,
//   Collapse,
//   CardActions,
//   Button,
//   TextField,
//   Stepper,
//   Step,
//   StepLabel,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material'
// import { LoadingButton } from '@mui/lab'
// import { styled } from '@mui/material/styles'
// import axios from 'axios'
// // component
// import Rate from 'components/rate/Rate'
// import type { Business } from 'types/customer'
// import CustomStepper from 'components/Stepper'
// import { rejects } from 'assert'
// import { type AxiosResponse } from 'axios'
// import SurveyRateForm from './SurveyRateForm'
// import SurveyProfileForm from './SurveyProfileForm'
// import { enqueueSnackbar } from 'notistack'
// import requestInstance from 'utils/request'
// import { type Customer } from 'types/customer'
// const StyledContent = styled('div')(({ theme }) => ({
//   maxWidth: 680,
//   margin: 'auto',
//   minHeight: '100vh',
//   display: 'flex',
//   justifyContent: 'flex-start',
//   flexDirection: 'column',
//   padding: theme.spacing(1, 1),
//   textAlign: 'center',
//   alignItems: 'center',
//   // color: theme.palette.text,
// }))

// const StyledCard = styled(Card)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   width: '100%',

//   height: 'auto',
//   px: 2,
//   py: 3,
//   // boxShadow: 0,
//   textAlign: 'center',
//   // color: (theme) => theme.palette[color].darker,
//   // bgcolor: (theme) => theme.palette[color].lighter,
// }))
// const StyledCardActions = styled(CardActions)(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'space-between',
// }))

// interface SurveyCustomerStepperProps {
//   business: Business
// }

// const SurveyCustomerStepper = ({ business }: SurveyCustomerStepperProps): JSX.Element => {
//   const [nextLoadingButton, setNextLoadingButton] = useState<boolean>(false)
//   const [backLoadingButton, setBackLoadingButton] = useState<boolean>(false)
//   const [profileFormEvent, setProfileFormEvent] = useState<boolean>(false)
//   const [formEvent, setFormEvent] = useState<string>('')
//   const [customerId, setCustomerId] = useState<string>('')
//   const [customer, setCustomer] = useState<Customer | null>(null)
//   const [profileForm, setProfileForm] = useState()

//   const theme = useTheme()
//   const matches = useMediaQuery(theme.breakpoints.up('md'))

//   const [activeStep, setActiveStep] = useState<number>(0)
//   const [rate, setRate] = useState<number>(-1)
//   const [formState, setFormState] = useState<any>('')
//   //  stepper
//   const [skipped, setSkipped] = useState(new Set())
// console.log(business);
//   // const handleRateChange = (rate: number): void => {
//   //   requestInstance
//   //     .post(`sale/`, {
//   //       rate,
//   //       business: business.id,
//   //     })
//   //     .then((res) => {
//   //       if (res.status === 201) {
//   //         setRate(rate)
//   //         // setLastSaleId(res.data.id);
//   //         console.log('successful')
//   //       }
//   //     })
//   //     .catch((err) => {
//   //       console.log(err)
//   //     })
//   // }
//   const isStepSkipped = (step: number): boolean => {
//     return skipped.has(step)
//   }
//   const isStepOptional = (step: number): boolean => {
//     return false
//   }

//   const steps = [
//     {
//       label: 'Profile',
//       component: (
//         < SurveyProfileForm
//           business={business}
//           setCustomer={setCustomer}
//           setCustomerId={setCustomerId}
//           businessId={business.id}
//           setActiveStep={setActiveStep}
//           formEvent={formEvent}
//           setFormEvent={setFormEvent}
//           setNextLoadingButton={setNextLoadingButton}
//           setBackLoadingButton={setBackLoadingButton}
//           activeStep={activeStep}
//           onSubmit={(param: number) => {
//             console.log(param)
//           }}
//         />
//       ),
//     },

//     {
//       label: 'Survey',
//       component: < SurveyRateForm
//         customer={customer}
//         customerId={customerId}
//         businessId={business.id}
//         setActiveStep={setActiveStep}
//         formEvent={formEvent}
//         setFormEvent={setFormEvent}
//         setNextLoadingButton={setNextLoadingButton}
//         setBackLoadingButton={setBackLoadingButton}
//         activeStep={activeStep}
//         onSubmit={(param: number) => {
//           console.log(param)
//         }}
//       />,
//     },

//   ]

//   return (
//     <StyledContent>
//       <Avatar src={business.icon} alt='photoURL' sx={{ width: 96, height: 96 }} />
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
//           {business.name}
//         </Typography>
//       </Box>

//       <StyledCard>
//         <CardContent>
//           <Stepper activeStep={activeStep}>
//             {steps.map((step, index) => {
//               return (
//                 <Step key={step.label} completed={isStepSkipped(index)}>
//                   <StepLabel
//                     optional={
//                       isStepOptional(index) ? (
//                         <Typography variant='caption'>Optional</Typography>
//                       ) : (
//                         <></>
//                       )
//                     }
//                   >
//                     {step.label}
//                   </StepLabel>
//                 </Step>
//               )
//             })}
//           </Stepper>
//           {steps[activeStep].component}
//           {activeStep >= 0 && activeStep < steps.length - 1 ? (
//             <StyledCardActions>
//               {activeStep > 0 &&
//                 <LoadingButton
//                   loading={backLoadingButton}
//                   sx={{ width: matches ? 'fit-content' : '100%', justifySelf: 'flex-start' }}
//                   onClick={() => {
//                     // steps[activeStep].nextHandler(setBackLoadingButton)
//                     setFormEvent(`${steps[activeStep].label}Back`)
//                   }}
//                   variant='outlined'
//                   size='large'
//                   color='secondary'
//                 >
//                   back
//                 </LoadingButton>
//               }
//               <LoadingButton
//                 loading={nextLoadingButton}
//                 sx={{ width: matches ? 'fit-content' : '100%' }}
//                 onClick={() => {
//                   // steps[activeStep].nextHandler(setNextLoadingButton)
//                   setFormEvent(`${steps[activeStep].label}Next`)

//                 }}
//                 variant='contained'
//                 size='large'
//               >
//                 Next
//               </LoadingButton>
//             </StyledCardActions>
//           ) : (
//             <></>
//           )}

//         </CardContent>
//       </StyledCard>
//     </StyledContent>
//   )
// }
// export default SurveyCustomerStepper

import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Typography,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  FormControl,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
// component
import Rate from 'components/rate/Rate'
import type { Business, Customer } from 'types/customer'

import requestInstance from 'utils/request'
import moment from 'moment'
// import SurveyProfileForm from './SurveyProfileForm'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import InputAdornment from '@mui/material/InputAdornment'
import TodayIcon from '@mui/icons-material/Today'
import { AppCountrySelect } from 'sections/@dashboard/app'
import countries from '_mock/countries'
import { type salesRecord } from 'types/salesRecord'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
  // color: theme.palette.text,
}))

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  height: 'auto',
  px: 2,
  py: 3,
  // boxShadow: 0,
  textAlign: 'center',
  // color: (theme) => theme.palette[color].darker,
  // bgcolor: (theme) => theme.palette[color].lighter,
}))
const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

interface SurveyCustomerStepperProps {
  business: Business
}

const SurveyCustomerStepper = ({ business }: SurveyCustomerStepperProps): JSX.Element => {
  const [nextLoadingButton, setNextLoadingButton] = useState<boolean>(false)
  // const [backLoadingButton, setBackLoadingButton] = useState<boolean>(false)

  // profile states
  const [name, setName] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [dob, setDob] = useState<string | null>(null)
  const [gender, setGender] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [countryPhone, setCountryPhone] = useState<string>(
    countries.find((item) => business.country === item.code)?.phone ?? '',
  )
  const [saleId, setSaleId] = useState<number>()
  const [customer, setCustomer] = useState<Customer | null>(null)
  // const []

  //  Rate states

  const [description, setDescription] = useState<string>('')
  const [rate, setRate] = useState<number>(-1)
  const [loadingButton, setLoadingButton] = useState(false)
  const [surveyDone, setSurveyDone] = useState<boolean>(false)

  // theme
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  //  stepper
  const [activeStep, setActiveStep] = useState<number>(0)
  // const [rate, setRate] = useState<number>(-1)
  const [skipped, setSkipped] = useState(new Set())
  const handleRateChange = (rate: number): void => {
    requestInstance
      .post<salesRecord>(`sale/customer/`, {
        rate,
        customer,
        business: business.id,
      })
      .then((res) => {
        if (res.status === 201) {
          setRate(rate)
          setSaleId(res.data.id)
          // seSaleId(res.data.id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmit = (): void => {
    setLoadingButton(true)
    Boolean(saleId) &&
      requestInstance
        .patch(`sale/${saleId}/`, {
          description,
        })
        .then((res) => {
          if (res.status === 200) {
            // enqueueSnackbar('your opinion submitted successfuly', { variant: 'success' })
            setSurveyDone(true)
          }
          setLoadingButton(false)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoadingButton(false)
        })
  }
  const isStepSkipped = (step: number): boolean => {
    return skipped.has(step)
  }
  const isStepOptional = (step: number): boolean => {
    return step === 0
  }

  const nextHandler = (): void => {
    setNextLoadingButton(true)

    if (activeStep === 0) {
      setCustomer({
        ...(name !== '' && { full_name: name }),
        ...(mobile !== '' && { mobile }),
        ...(dob !== null && { dob: moment(dob).format('YYYY-MM-DD') }),
        ...(gender !== '' && { gender }),
        ...(email !== '' && { email }),
        ...(countryPhone !== '' && { country_code: countryPhone }),
      })
      setNextLoadingButton(false)
      setActiveStep((prev) => prev + 1)
      // requestInstance
      //   .post<Customer>(`customer/`, {
      // ...(name !== '' && { full_name: name }),
      // ...(mobile !== '' && { mobile }),
      // ...(dob !== null && { dob: moment(dob).format('YYYY-MM-DD') }),
      // ...(gender !== '' && { gender }),
      // ...(email !== '' && { email }),
      // ...(countryPhone !== '' && { country_code: countryPhone }),
      //     business: business.id,
      //   })
      //   .then((res) => {
      //     console.log('then')
      //     setCustomerId(res.data.id ?? '')
      //     setNextLoadingButton(false)
      //     setActiveStep((prev) => prev + 1)
      //   })
      //   .catch((err) => {
      //     console.log('errrr')
      //     console.log(err)
      //     setNextLoadingButton(false)
      //     // throw err
      //   })
    }
  }
  const handleSkip = (): void => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }
  const SurveyProfileForm = (
    <form
      autoComplete='off'
      // onSubmit={() => { onSubmit(10) }}
    >
      <Grid
        sx={{ padding: '15px 0' }}
        container
        spacing={2}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Grid flexDirection={'row'} spacing={2} display={'flex'} container flexWrap={'nowrap'}>
            <Grid item xs={4}>
              <AppCountrySelect countryPhone={countryPhone} onChange={setCountryPhone} />
            </Grid>
            <Grid item xs={8}>
              <TextField
                type='tel'
                placeholder='Mobile'
                inputProps={{
                  type: 'tel',
                }}
                value={mobile}
                fullWidth
                onChange={(event) => {
                  setMobile(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <FormControl fullWidth sx={{ textAlign: 'left' }}>
            <TextField
              key='fullName'
              name='fullName'
              label='Name'
              fullWidth
              autoComplete='none'
              value={name}
              onChange={(event) => {
                setName(event.target.value)
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <FormControl fullWidth sx={{ textAlign: 'left' }}>
            <FormLabel id='demo-row-radio-buttons-group-label' sx={{ textAlign: 'left' }}>
              Gender (optional)
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              value={gender}
              onChange={(event) => {
                console.log(gender)
                setGender(event.target.value)
              }}
            >
              <FormControlLabel value='F' control={<Radio />} label='Female' />
              <FormControlLabel value='M' control={<Radio />} label='Male' />
              <FormControlLabel value='O' control={<Radio />} label='Other' />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <FormControl fullWidth sx={{ textAlign: 'left' }}>
            <MobileDatePicker
              // label="Date of birth (optional)"
              sx={{ width: '100%' }}
              value={dob}
              onChange={(newValue) => {
                setDob(newValue)
              }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      placeholder: 'Date of birth (optional)',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <TodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <FormControl fullWidth sx={{ textAlign: 'left' }}>
            <TextField
              key='email'
              name='email'
              label='Email (optional)'
              fullWidth
              value={email}
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              autoComplete='none'
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  )

  const SurveyRateForm = (
    <>
      {surveyDone ? (
        <Typography sx={{ mt: 2, mb: 1 }}>Thank you for your time.</Typography>
      ) : (
        <Box>
          <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Collapse in={rate < 0} timeout='auto' unmountOnExit>
              <Typography sx={{ color: 'text.secondary' }}>
                From 0 to 10, how likely are you to recommend us to a friend or colleague?
              </Typography>
              {/* <Rate value={rate} setter={setRate} lastSaleId={lastSaleId} /> */}
              <Rate value={rate} onChange={handleRateChange} />
              {/* <Divider variant="middle" /> */}
            </Collapse>

            <Collapse in={rate >= 0} timeout='auto'>
              {rate < 7 && (
                <Box>
                  <Typography sx={{ color: 'text.secondary' }}>
                    What are the main reasons for your satisfaction or dissatisfaction?
                  </Typography>
                  <TextField
                    multiline
                    rows={6}
                    placeholder='Write here...'
                    sx={{ padding: '15px 0', width: '100%' }}
                    value={description}
                    onChange={(event) => {
                      setDescription(event.target.value)
                    }}
                  />
                  <CardActions sx={{ px: 0 }}>
                    <LoadingButton
                      loading={loadingButton}
                      sx={{ width: '100%' }}
                      size='large'
                      variant='contained'
                      onClick={handleSubmit}
                    >
                      Submit
                    </LoadingButton>
                  </CardActions>
                </Box>
              )}
              {rate >= 7 && (
                <Box>
                  <Typography sx={{ mt: 2, mb: 1 }}>Thank you for your time.</Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Please leave us a review on Google and tell your most satisfaction reasons.
                  </Typography>
                  {/* <StyledCardActions> */}
                  <Button
                    sx={{ width: '100%' }}
                    size='large'
                    variant='contained'
                    onClick={() => window.open(business?.google_link, '_self')}
                  >
                    Open Google
                  </Button>
                  {/* </StyledCardActions> */}
                </Box>
              )}
            </Collapse>
          </CardContent>
        </Box>
      )}
    </>
  )
  const steps = [
    {
      label: 'Profile',
      component: SurveyProfileForm,
    },

    {
      label: 'Survey',
      component: SurveyRateForm,
    },
  ]

  return (
    <>
      <StyledContent>
        <Avatar src={business.icon} alt='photoURL' sx={{ width: 96, height: 96 }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            {business.name}
          </Typography>
        </Box>

        <StyledCard>
          <CardContent>
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => {
                return (
                  <Step key={step.label} completed={isStepSkipped(index)}>
                    <StepLabel
                      optional={
                        isStepOptional(index) ? (
                          <Typography variant='caption'>Optional</Typography>
                        ) : (
                          <></>
                        )
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            {steps[activeStep].component}
            {activeStep >= 0 && activeStep < steps.length - 1 ? (
              <StyledCardActions>
                {activeStep > 0 && (
                  <LoadingButton
                    // loading={backLoadingButton}
                    sx={{ width: matches ? 'fit-content' : '100%', justifySelf: 'flex-start' }}
                    // onClick={() => {

                    // }}
                    variant='outlined'
                    size='large'
                    color='secondary'
                  >
                    back
                  </LoadingButton>
                )}
                {isStepOptional(activeStep) && (
                  <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }} variant='outlined'>
                    Skip
                  </Button>
                )}
                <LoadingButton
                  loading={nextLoadingButton}
                  sx={{ width: matches ? 'fit-content' : '100%' }}
                  onClick={nextHandler}
                  variant='contained'
                  size='large'
                >
                  Next
                </LoadingButton>
              </StyledCardActions>
            ) : (
              <></>
            )}
          </CardContent>
        </StyledCard>
        <Box sx={{ textAlign: 'center', marginTop: 'auto', marginBottom: '50px' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default SurveyCustomerStepper

import React from 'react'

import {
  Avatar,
  Box,
  Typography,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  //   textAlign: 'center',
  alignItems: 'center',
}))

const StyledCard = styled(Card)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  width: '100%',
  //   height:'100%',
  //   justifyContent: 'space-between',
  flex: 1,
}))

const ResDetailPage = (): JSX.Element => {
  return (
    <>
      <StyledContent>
        <Avatar
          src='/assets/images/demo/TFS logo.png'
          alt='photoURL'
          sx={{ width: 96, height: 96 }}
        />
        <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
        Demo Restaurant
        </Typography>
        <StyledCard>
          <CardMedia
            component='img'
            // src='https://placehold.co/400x400'
            image='/assets/images/demo/Restaurant-birthday-gift.jpg'
            alt='Live from space album cover'
          />
          <CardContent sx={{ overflowY: 'auto' }}>
            <Typography variant='h5'>Birthday Cake</Typography>
            <Typography variant='subtitle2' color='text.secondary'>0 Points</Typography>
            <Typography variant='body1'>
              Sed ut sodales est. Mauris leo nunc, fringilla eget velit ut, egestas imperdiet justo.
              Aenean ac ligula sem. Suspendisse potenti. Suspendisse id tellus id felis venenatis
              lacinia. Nulla eget viverra dui. Aliquam dignissim, odio ac placerat aliquam, nibh
              diam facilisis mauris, at porta purus velit nec orci. Suspendisse nec nulla mauris.
              Cras at bibendum enim, vel venenatis risus. Nam fringilla euismod purus at facilisis.
            </Typography>
          </CardContent>
        </StyledCard>
        <Box sx={{ marginTop: 'auto' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
      <Button
        size='large'
        fullWidth
        variant='contained'
        sx={{ position: 'sticky', bottom: 0, borderRadius: 0 }}
      >
        redeem
      </Button>
    </>
  )
}
export default ResDetailPage

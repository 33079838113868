import {
  // Grid,
  // Container,
  Typography,
  // Box,
  Card,
  Button,
  // Fab,
  Stack,
  TextField,
  SwipeableDrawer,
  // Paper,
  type SxProps,
  useTheme,
  Modal,
  Fade,
  Backdrop,
  CardActions,
  useMediaQuery,
} from '@mui/material'
// import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
// import axios from 'axios'
import {
  // useEffect,
  useState,
} from 'react'
// import { useLoaderData } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'
// import countries from '../_mock/countries';
// components
// sections
import AppCountrySelect from './AppCountrySelect'

const style: SxProps = {
  borderRadius: '8px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
interface AppSalesRecordModalProps {
  defaultCountryPhone: string
  id: string
  open: boolean
  handleClose: () => void
  handleOpen: () => void
}
const AppSalesRecordModal = ({
  id,
  open,
  handleClose,
  handleOpen,
  defaultCountryPhone,
}: AppSalesRecordModalProps): JSX.Element => {
  const [mobile, setMobile] = useState<string | null>()
  const [countryPhone, setCountryPhone] = useState<string>(defaultCountryPhone)
  const navigate = useNavigate()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const handleSearch = (): void => {
    if (mobile !== null && countryPhone !== null) {
      navigate({
        pathname: `/customer`,
        search: createSearchParams({
          mobile: mobile ?? '',
          country_code: `${countryPhone}` ?? '',
          business: id,
        }).toString(),
      })
    }
    // axios
    //   .get(`/customer/?mobile=${mobile}&business=${id}&country=${countryCode}`)
    //   .then((res) => {
    //     if (res.status === 200) {
          //   if (res.data.data.length) {
          // console.log('found')
          // console.log(res.data)
          // if(res.data.data.length > 0){

          //   navigate(`/customer/${res.data.data[0].id}`)
          // }else{
          //   navigate(`/customer`)
          // }

          //   }
          //    else {
          //     console.log('new')
          //     navigate(`/dashboard/customer`, { state: { id: id, mobile, countryCode } })
          //   }
      //   }
      // })
      // .catch((err) => {
      //   console.log(err)
      // })
  }
  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  return (
    <>
      {matches && (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Card sx={style} elevation={5}>
              <Stack spacing={1}>
                <Typography id='transition-modal-title' variant='h6' component='h2'>
                  Sales Record
                </Typography>
                <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                  Please search for customer mobile:
                </Typography>
                <Stack direction={'row'} alignItems={'flex-end'} spacing={1}>
                  <AppCountrySelect
                    countryPhone={countryPhone}
                    onChange={setCountryPhone}
                    // value={countryCode}
                  />

                  <TextField
                    type='tel'
                    placeholder='Mobile'
                    sx={{ mt: 2 }}
                    inputProps={{
                      type: 'tel',
                    }}
                    value={mobile}
                    onChange={(event) => {
                      setMobile(event.target.value)
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Stack>
              </Stack>
              {
                <CardActions sx={{ mt: 2, width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                  <Button
                    onClick={handleSearch}
                    variant='contained'
                    color='secondary'
                    sx={{ width: '100%' }}
                    size='large'
                  >
                    Search
                  </Button>
                </CardActions>
              }
            </Card>
          </Fade>
        </Modal>
      )}
      {!matches && (
        <SwipeableDrawer
          anchor={'bottom'}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          sx={{ '& .MuiPaper-root': { padding: '10px', borderRadius: '10px 10px 0 0 ' } }}
        >
          <Stack spacing={2}>
            <Typography id='transition-modal-title' variant='h6' component='h2'>
              Sales Record
            </Typography>
            <Typography id='transition-modal-description' sx={{ mt: 2 }}>
              Please search for customer mobile:
            </Typography>
            <Stack direction={'row'} alignItems={'flex-end'} spacing={1}>
              <AppCountrySelect
                countryPhone={countryPhone}
                onChange={setCountryPhone}
                // value={countryCode}
              />

              <TextField
                type='tel'
                placeholder='Mobile'
                sx={{ mt: 2 }}
                inputProps={{
                  type: 'tel',
                }}
                value={mobile}
                onChange={(event) => {
                  setMobile(event.target.value)
                }}
                onKeyDown={handleKeyDown}
              />
            </Stack>
          </Stack>
          {
            <CardActions sx={{ mt: 2, width: '100%', paddingLeft: 0, paddingRight: 0 }}>
              <Button
                onClick={handleSearch}
                variant='contained'
                color='secondary'
                sx={{ width: '100%' }}
                size='large'
              >
                Search
              </Button>
            </CardActions>
          }
        </SwipeableDrawer>
      )}
    </>
  )
}
export default AppSalesRecordModal

import axios from 'axios' //  { Method, AxiosResponse }
import { notificationActions } from 'store/notification-slice'
// import { useAppDispatch } from 'store/hooks'
import store from 'store'
// const api = axios.create({
//   // baseURL: process.env.REACT_APP_HOST_BACKEND,
//   baseURL: "https://rickandmortyapi.com/api",
// });

// const request = async <T>(
//   method: Method,
//   url: string,
//   params: any
// ): Promise<AxiosResponse<T>> => {
//   return await api.request<T>({
//     method,
//     url,
//     params,
//   });
// };
const requestInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    // "Content-Type":'application/json;charset=utf-8'
    //  Authorization: `<Your Auth Token>`,
    // Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')!)?JSON.parse(localStorage.getItem('user')!).token:''}`
  },
  // .. other options
})
requestInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    Object.keys(error.response.data).forEach(item =>{
      store.dispatch(notificationActions.enqueueSnackbar({
        message: `${item}: ${JSON.stringify(error.response.data[item])}`,
        key: `${new Date().getTime() + Math.random()}`,
        dismissed: false,
        options:{variant:'error'}
      }))
    })
    throw error
  },
)

export default requestInstance

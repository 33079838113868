import React from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';
import { type ApexOptions } from "apexcharts";
// ----------------------------------------------------------------------
interface AppWebsiteVisitsProps  {
  title:string, 
  subheader:string, 
  fill:string[],
  chartLabels:string[], 
  chartData :ApexOptions["series"],
}

const AppWebsiteVisits = ({ title, subheader, chartLabels, chartData,fill }:AppWebsiteVisitsProps): JSX.Element=>  {
  const chartOptions : ApexOptions= useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: fill.map((i) => i) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y:number) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card  sx={{ height: '100%' }}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
export default AppWebsiteVisits
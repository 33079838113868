import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import countries from '_mock/countries'

interface AppCountrySelectProps {
  disabled?: boolean
  countryPhone: string
  onChange: (val: string) => void
}

const AppCountrySelect = ({ onChange, countryPhone, disabled = false }: AppCountrySelectProps): JSX.Element => {
  const [value, setValue] = useState(countries.find((o) => o.phone === countryPhone))
  useEffect(()=>{
    console.log(countries.find((o) => o.phone === countryPhone));
    setValue(countries.find((o) => o.phone === countryPhone))
  },[
    countryPhone
  ])
console.log(value);
  return (
    <Autocomplete
      disabled={disabled}
      disableClearable
      id='country-select-demo'
      value={value}
      onChange={(event, newValue) => {
        console.log('newValue')
        console.log(newValue)
        setValue(newValue)
        onChange(newValue.phone)
      }}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: '17px !important',
        },
      }}
      options={countries}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => `+${option.phone}`}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.code}
        >
          <img
            key={option.code}
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          {option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          sx={{
            minWidth: '120px',
            width: '100%'
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment:
              value !== undefined ? (
                <InputAdornment position='start' key={value.code}>
                  <img
                    key={value.code}
                    loading='lazy'
                    src={`https://flagcdn.com/w20/${value.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${value.code.toLowerCase()}.png 2x`}
                    alt=''
                  />
                  
                </InputAdornment>
              ) : null,
          }}
        />
      )}
    />
  )
}
export default AppCountrySelect
